import React, { useState, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import $ from "jquery"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"

import t from "locale"

const Contact = ({ contactFields }) => {
  const lang = "de"
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [isOpenArrow, setIsOpenArrow] = useState(false)
  const [query, setQuery] = useState("")
  const [filterDepartment, setFilterDepartment] = useState("none")

  const openFormHandler = () => {
    setIsOpenForm(!isOpenForm)
    setIsOpenArrow(!isOpenArrow)
  }

  const seo = contactFields.seo
  const image = contactFields.featuredImage

  useEffect(() => {
    $(".searchInput").keyup(function () {
      const salesLenght = $("#sales .col-count").length
      const hatcheryLenght = $("#hatchery .col-count").length
      const seaFarmingLength = $("#sea-farming .col-count").length
      const packingFacilityLength = $("#packing-facility .col-count").length
      const administrationLength = $("#administration .col-count").length

      if (salesLenght === 0) {
        $("#sales").hide()
      } else {
        $("#sales").show()
      }
      if (hatcheryLenght === 0) {
        $("#hatchery").hide()
      } else {
        $("#hatchery").show()
      }
      if (seaFarmingLength === 0) {
        $("#sea-farming").hide()
      } else {
        $("#sea-farming").show()
      }
      if (packingFacilityLength === 0) {
        $("#packing-facility").hide()
      } else {
        $("#packing-facility").show()
      }
      if (administrationLength === 0) {
        $("#administration").hide()
      } else {
        $("#administration").show()
      }
    })
  })

  return (
    <Layout
      lang={lang}
      translationDE="/de/kontakt/"
      translationES="/es/contact/"
      translationIT="/it/contatti/"
      translationFR="/fr/contact/"
    >
      {/* eslint-disable jsx-a11y/no-onchange */}
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={image ? image.sourceUrl : "https://hiddenfjord.com/og-image.png"}
      />

      <section className="contact-header">
        <div className="container">
          <h1 className="contact-header__title">{t("Contact", lang)}</h1>
          <p className="contact-header__name">HIDDENFJORD</p>
          <p className="mb-20">
            {t("Phone", lang)}:{" "}
            <a href="tel:+298662100" className="contact-header__links">
              +298 662100
            </a>
          </p>
          <p className="text-center mb-20">
            {t("Sales", lang)}: <br />
            <a
              href="mailto:sales@hiddenfjord.com"
              className="contact-header__links links--email"
            >
              sales@hiddenfjord.com
            </a>
          </p>
          <p className="text-center mb-20">
            {t("Administration", lang)}: <br />
            <a
              href="mailto:mail@hiddenfjord.com"
              className="contact-header__links links--email"
            >
              mail@hiddenfjord.com
            </a>
          </p>
          <p
            className="contact-header__contact-button contact-button"
            onClick={openFormHandler}
            role="presentation"
          >
            {t("Message form", lang)}
            <span
              className={`contact-button__arrow ${
                !isOpenArrow === true ? "opened" : ""
              }`}
            ></span>
          </p>
          <div
            className={`contact-header__form-container form-container ${
              isOpenForm === true ? "opened" : ""
            }`}
          >
            <div className="contact-form">
              <p className="contact-form__title">{t("Claim form", lang)}</p>
              <ContactForm lang={lang} />
            </div>
          </div>
        </div>
      </section>

      <section className="contact-main-section">
        <div className="container">
          <div className="search-container">
            <div className="row">
              <div className="col-md-6">
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="search"
                    placeholder="Name"
                    value={query}
                    onChange={({ target: { value } }) => setQuery(value)}
                    className="searchInput"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="select-wrapper">
                  <select
                    id="department"
                    value={filterDepartment}
                    onChange={e => setFilterDepartment(e.target.value)}
                  >
                    <option value="none">{t("All Departments", lang)}</option>
                    <option value="sales">MARKETING & VERTRIEB</option>
                    <option value="hatchery">LACHSAUFZUCHT</option>
                    <option value="sea-farming">SEA FARMING</option>
                    <option value="packing-facility">VERARBEITUNGSANLAG</option>
                    <option value="administration">ADMINISTRATION</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="team-container">
            {filterDepartment === "sales" || filterDepartment === "none" ? (
              <div className="main-team" id="sales">
                <h2 className="main-team__title">MARKETING & VERTRIEB</h2>
                <div className="row justify-content-center">
                  {contactFields.acfContact.salesPersons
                    .filter(
                      ({ salesPersonName }) =>
                        salesPersonName.includes(query.toLowerCase()) ||
                        salesPersonName
                          .toLowerCase()
                          .includes(query.toLowerCase())
                    )
                    .map((item, index) => (
                      <div className="col-lg-4 col-md-6 col-count" key={index}>
                        <div className="single-person">
                          {item.salesPersonImage && (
                            <img
                              src={item.salesPersonImage.sourceUrl}
                              alt={item.salesPersonName}
                              className="img-fluid single-person__photo"
                            />
                          )}
                          <p className="single-person__name">
                            {item.salesPersonName}
                          </p>
                          <p className="single-person__position">
                            {item.salesPersonPosition}
                          </p>
                          <a
                            href={`tel:${item.salesPersonPositionPhone}`}
                            className="single-person__phone"
                          >
                            {item.salesPersonPositionPhone}
                          </a>
                          <a
                            href={`mailto:${item.salesPersonPositionEmail}`}
                            className="single-person__email"
                          >
                            {item.salesPersonPositionEmail}
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {filterDepartment === "hatchery" || filterDepartment === "none" ? (
              <div className="team-text" id="hatchery">
                <h2 className="team-text__title">LACHSAUFZUCHT</h2>
                <div className="row justify-content-center">
                  {contactFields.acfContact.hatcheryPersons
                    .filter(
                      ({ hatcheryPersonName }) =>
                        hatcheryPersonName.includes(query.toLowerCase()) ||
                        hatcheryPersonName
                          .toLowerCase()
                          .includes(query.toLowerCase())
                    )
                    .map((item, index) => (
                      <div className="col-lg-4 col-md-6 col-count" key={index}>
                        <div className="single-person">
                          {item.hatcheryPersonImage && (
                            <img
                              src={item.hatcheryPersonImage.sourceUrl}
                              alt={item.hatcheryPersonName}
                              className="img-fluid single-person__photo"
                            />
                          )}
                          <p className="single-person__name">
                            {item.hatcheryPersonName}
                          </p>
                          <p className="single-person__position">
                            {item.hatcheryPersonPosition}
                          </p>
                          <a
                            href={`tel:${item.hatcheryPersonPositionPhone}`}
                            className="single-person__phone"
                          >
                            {item.hatcheryPersonPositionPhone}
                          </a>
                          <a
                            href={`mailto:${item.hatcheryPersonPositionEmail}`}
                            className="single-person__email"
                          >
                            {item.hatcheryPersonPositionEmail}
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {filterDepartment === "sea-farming" ||
            filterDepartment === "none" ? (
              <div className="team-text" id="sea-farming">
                <h2 className="team-text__title">SEA FARMING</h2>
                <div className="row justify-content-center">
                  {contactFields.acfContact.farmingPersons
                    .filter(
                      ({ farmingPersonName }) =>
                        farmingPersonName.includes(query.toLowerCase()) ||
                        farmingPersonName
                          .toLowerCase()
                          .includes(query.toLowerCase())
                    )
                    .map((item, index) => (
                      <div className="col-lg-4 col-md-6 col-count" key={index}>
                        <div className="single-person">
                          {item.farmingPersonImage && (
                            <img
                              src={item.farmingPersonImage.sourceUrl}
                              alt={item.farmingPersonName}
                              className="img-fluid single-person__photo"
                            />
                          )}
                          <p className="single-person__name">
                            {item.farmingPersonName}
                          </p>
                          <p className="single-person__position">
                            {item.farmingPersonPosition}
                          </p>
                          <a
                            href={`tel:${item.farmingPersonPositionPhone}`}
                            className="single-person__phone"
                          >
                            {item.farmingPersonPositionPhone}
                          </a>
                          <a
                            href={`mailto:${item.farmingPersonPositionEmail}`}
                            className="single-person__email"
                          >
                            {item.farmingPersonPositionEmail}
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {filterDepartment === "packing-facility" ||
            filterDepartment === "none" ? (
              <div className="team-text" id="packing-facility">
                <h2 className="team-text__title">VERARBEITUNGSANLAG</h2>
                <div className="row justify-content-center">
                  {contactFields.acfContact.processingPersons
                    .filter(
                      ({ processingPersonName }) =>
                        processingPersonName.includes(query.toLowerCase()) ||
                        processingPersonName
                          .toLowerCase()
                          .includes(query.toLowerCase())
                    )
                    .map((item, index) => (
                      <div className="col-lg-4 col-md-6 col-count" key={index}>
                        <div className="single-person">
                          {item.processingPersonImage && (
                            <img
                              src={item.processingPersonImage.sourceUrl}
                              alt={item.processingPersonName}
                              className="img-fluid single-person__photo"
                            />
                          )}
                          <p className="single-person__name">
                            {item.processingPersonName}
                          </p>
                          <p className="single-person__position">
                            {item.processingPersonPosition}
                          </p>
                          <a
                            href={`tel:${item.processingPersonPositionPhone}`}
                            className="single-person__phone"
                          >
                            {item.processingPersonPositionPhone}
                          </a>
                          <a
                            href={`mailto:${item.processingPersonPositionEmail}`}
                            className="single-person__email"
                          >
                            {item.processingPersonPositionEmail}
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {filterDepartment === "administration" ||
            filterDepartment === "none" ? (
              <div className="team-text" id="administration">
                <h2 className="team-text__title">ADMINISTRATION</h2>
                <div className="row justify-content-center">
                  {contactFields.acfContact.administrationPersons
                    .filter(
                      ({ administrationPersonName }) =>
                        administrationPersonName.includes(
                          query.toLowerCase()
                        ) ||
                        administrationPersonName
                          .toLowerCase()
                          .includes(query.toLowerCase())
                    )
                    .map((item, index) => (
                      <div className="col-lg-4 col-md-6 col-count" key={index}>
                        <div className="single-person">
                          {item.administrationPersonImage && (
                            <img
                              src={item.administrationPersonImage.sourceUrl}
                              alt={item.administrationPersonName}
                              className="img-fluid single-person__photo"
                            />
                          )}
                          <p className="single-person__name">
                            {item.administrationPersonName}
                          </p>
                          <p className="single-person__position">
                            {item.administrationPersonPosition}
                          </p>
                          <a
                            href={`tel:${item.administrationPersonPositionPhone}`}
                            className="single-person__phone"
                          >
                            {item.administrationPersonPositionPhone}
                          </a>
                          <a
                            href={`mailto:${item.administrationPersonPositionEmail}`}
                            className="single-person__email"
                          >
                            {item.administrationPersonPositionEmail}
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        contactFields: wpPage(id: { eq: "cG9zdDo0MDI=" }) {
          acfContact {
            salesPersons {
              salesPersonImage {
                sourceUrl
              }
              salesPersonName
              salesPersonPosition
              salesPersonPositionPhone
              salesPersonPositionEmail
            }
            hatcheryPersons {
              hatcheryPersonImage {
                sourceUrl
              }
              hatcheryPersonName
              hatcheryPersonPosition
              hatcheryPersonPositionPhone
              hatcheryPersonPositionEmail
            }
            farmingPersons {
              farmingPersonImage {
                sourceUrl
              }
              farmingPersonName
              farmingPersonPosition
              farmingPersonPositionPhone
              farmingPersonPositionEmail
            }
            processingPersons {
              processingPersonImage {
                sourceUrl
              }
              processingPersonName
              processingPersonPosition
              processingPersonPositionPhone
              processingPersonPositionEmail
            }
            administrationPersons {
              administrationPersonImage {
                sourceUrl
              }
              administrationPersonName
              administrationPersonPosition
              administrationPersonPositionPhone
              administrationPersonPositionEmail
            }
          }
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    `}
    render={data => <Contact {...data} />}
  />
)
